<template>
  <v-row>
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true"></mini-header>
    <!-- end header -->
    <!-- <v-row class="mt-16" align="center">
      <v-empty-state title="En fase de creación" type="create" id="tiendas" customClass="mt-7" :height="300" :hiddenDescription="true" :isFree="true"/>
    </v-row>
     -->
    <v-col class="px-0 mt-n10">
      <VSkeleton class="mt-6" v-if="loading" />
      <template v-else>
        <v-col cols="12" class="px-0 mt-4">
          <template v-if="pos_groupsList.length">
            <div>
              <v-toolbar-title class="subtitle-1 defaultGrey--text font-weight-bold mb-4">Grupos de la organización</v-toolbar-title>
              <v-row>
                <v-col v-for="(d, index) in pos_groupsList.filter(({visibility}) => visibility !== 3)" :key="`${d.id}-${index}`" cols="4" style="max-width: 270px">
                  <v-hover v-slot="{ hover }">
                    <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="$router.push({ name: 'SupplierListCreate',  query: {ordering: '-date,-created'}, params: {...$route.params} })">
                      <v-row no-gutters>
                        <v-avatar color="lightBlue" size="32">
                          <v-icon color="primary" size="17">mdi-folder</v-icon>
                        </v-avatar>
                        <v-spacer />
                        <v-menu v-model="menu[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="10" transition="slide-y-transition" :min-width="182">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                              <v-icon color="secondary" size="24">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0 pb-2">
                            <v-list-item-title class="px-3 pt-4 pb-2 body-1 font-weight-semibold">Accesos directos</v-list-item-title>
                            <v-list-item class="px-3" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los proveedores</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los documentos</v-list-item-title>
                            </v-list-item>
                            <v-divider class="mx-3" />
                            <v-list-item class="px-3 mt-1" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Agregar al menú</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleGroup(d)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar grupo</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleDestroyGroup(d.id)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Eliminar grupo</v-list-item-title> <!--/Eliminar -->
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <span class="d-block pt-3">
                        <span class="fontBody--text font-weight-semibold body-1 d-inline-block text-truncate" style="max-width: 160px;">{{d.name}}</span>
                      </span>
                      <span class="d-block body-1 mt-2 fontDraw--text">Cantidad de proveedores</span>
                      <span class="d-block fontDraw--text mt-1 body-2">
                        {{d.members.length}}
                      </span>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <!-- v-if="pos_groupsList.filter(({visibility}) => visibility === 3).length" -->
            <div class="mt-8">
              <v-toolbar-title class="subtitle-1 defaultGrey--text font-weight-bold mb-4">Tus Grupos</v-toolbar-title>
              <v-row>
                <v-col v-for="(d, index) in pos_groupsList.filter(({visibility}) => visibility === 3)" :key="`${d.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                  <v-hover v-slot="{ hover }">
                    <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="goTo(d)">
                      <v-row no-gutters>
                        <v-avatar color="lightBlue" size="32">
                          <v-icon color="primary" size="17">mdi-folder</v-icon>
                        </v-avatar>
                        <v-spacer />
                        <v-menu v-model="menu1[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="10" transition="slide-y-transition" :min-width="182">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                              <v-icon color="secondary" size="24">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0 pb-2">
                            <v-list-item-title class="px-3 pt-4 pb-2 body-1 font-weight-semibold">Accesos directos</v-list-item-title>
                            <v-list-item class="px-3" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los proveedores</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los documentos</v-list-item-title>
                            </v-list-item>
                            <v-divider class="mx-3" />
                            <v-list-item class="px-3 mt-1" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Agregar al menú</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleGroup(d)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar grupo</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleDestroyGroup(d.id)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Eliminar grupo</v-list-item-title> <!--/Eliminar -->
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <span class="d-block pt-3">
                        <span class="fontBody--text font-weight-semibold body-1 d-inline-block text-truncate" style="max-width: 160px;">{{ d.name }}</span>
                      </span>
                      <span class="d-block body-1 mt-1 fontDraw--text">Cantidad de proveedores</span>
                      <span class="d-block fontDraw--text mt-1 body-2">
                        {{d.members.length}}
                      </span>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
        <v-empty-state v-if="!pos_groupsList.length" :title="`No existen grupos ${emptyStateFilter(filters) ? 'para mostrar' : ''}`" :description="emptyStateFilter(filters) ? 'Prueba cambiar o eliminar algunos filtros o ajustar la zona de búsqueda.' : ''" type="pos" :height="env ? 320 : 200" />
      </template>
    </v-col>

    <!-- dialog edit group -->
    <v-dialog v-model="dialogGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Editar grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.description.$model" :error="$v.editable.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div class="">
            <span class="d-block mt-5 body-1 fontBody--text font-weight-bold">Visibilidad</span>
            <v-radio-group class="mx-0 ml-n1" v-model="editable.visibility" dense hide-details>
              <v-radio label="Todos los miembros de la organización" :value="1" hide-details />
              <v-radio label="Algunos miembros de la organización" :value="2" hide-details />
              <v-radio label="Solo yo" :value="3" hide-details />
            </v-radio-group>
          </div>
          <template v-if="![1, 3].includes(editable.visibility)">
            <v-list class="pa-0 mt-5 transparent v-list-form-extended">
              <v-list-item class="py-1">
                <v-list-item-title>Compartir con</v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="editable.members"
                      :error="$v.editable.members.$error"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      return-object
                      :prefix="editable.members.length >= 1 ? `${editable.members.length === 1 ? `${editable.members.length} seleccionado` : `${editable.members.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col class="pl-9 mt-2">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in editable.members" :key="`${member}${i}`" close @click:close="remove(member)" color="lightBlue" small label close-icon="mdi-close">
                  {{ member.email }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogGroup=false">Cancelar</v-btn>
          <v-btn color="primary" @click="editGroup" :loading="editGroupLoading">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit group -->

    <!-- dialog confirm list -->
    <v-dialog v-model="dialogDelete" width="709" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-medium">Eliminar grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3" class="mt-7">
              <img :src="require(`@/assets/backgrounds/modal-segment-destroy.svg`)" :width="160" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al eliminar el grupo, los proveedores asociados a este grupo seguirán activos y podrán ser asignados a otros grupos en el futuro.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="dialogDelete=false" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm list -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import groupsList from '@/data/groupsSegmentsList'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import VCardList from '@/modules/pos/pos/components/complements/VCardList'
import VHeader from '@/modules/pos/pos/components/complements/VHeader'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import ListViewMixin from '@/mixins/ListViewMixin'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VTableHeaders,
    SkeletonList,
    VEmptyState,
    VCardList,
    VHeader,
    VSkeleton,
    VQueryBuilder,
    VMainPagination,
    MiniHeader
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    confirm: '',
    dialogDelete: false,
    editGroupLoading: false,
    dialogGroup: false,
    menu: [false],
    menu1: [false],
    scroll: 0,
    loading: false,
    filters: [
    {
        id: 'branch',
        label: 'Tienda',
        choices: [],
        type: 'select',
        value: null,
        category: 'a'
      },
      {
        id: 'name',
        label: 'Nombre',
        operators: [],
        type: 'text',
        value: null,
        category: 'b'
      }
    ],
    editable: {
      name: '',
      visibility: 3,
      description: '',
      pos: [],
      members: []
    },
    idDelete: '',
    breadcrumbs: {
     main: 'Proveedores',
     children: [
      {
        text: 'Segmentos'
      }
     ]
   }
  }),
  watch: {
    // hn
  },
  computed: {
    ...mapState({
      // pos_groupsList: state => state.pos.pos_groupsList,
      membersList: state => state.accounts.membersList
    })
  },
  created () {
    // this.getList()
    this.pos_groupsList = groupsList
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    async getList () {
      // this.loading = true
      // let query = JSON.parse(JSON.stringify(this.$route.query))
      // if (this.$route.query.name) {
      //   delete query.name
      // }

      // await this.$store.dispatch('pos/LIST', {
      //   resource: 'pos_groups',
      //   query
      // })
      //   this.loading = false
    },
    handleGroup (item = {}) {
      this.dialogGroup = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
      .then((resp) => {
        this.editable = {
          id: item.id,
          name: item.name,
          visibility: item.visibility,
          description: item.description,
          pos: item.pos,
          members: resp.data.results.filter(({id}) => item.members.includes(id))
        }
      })
    },
    memberRemove (item) {
      const index = this.editable.members.indexOf(item)
      if (index >= 0) this.editable.members.splice(index, 1)
    },
    handleDestroyGroup (id = '') {
      this.idDelete = id
      this.dialogDelete = true
    },
    editGroup () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }

      this.editGroupLoading = true

      let payload = {...this.editable}
      if ([1, 3].includes(this.editable.visibility)) delete payload.members
      else payload.members = payload.members.map(({id}) => id)

      delete payload.id

      this.$store.dispatch('pos/UPDATE', {
        resource: 'pos_groups',
        id: this.editable.id,
        query: this.$route.query,
        payload: payload
      })
     .then(() => {
        this.$dialog.message.info('El grupo se ha actualizado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.editable.$reset()
        this.editable = {
          name: '',
          visibility: 3,
          description: '',
          pos: [],
          members: []
        }
        this.dialogGroup = false
        this.editGroupLoading = false
      })
    },
    destroyGroup () {
      this.$store.dispatch('pos/DESTROY', {
        resource: 'pos_groups',
        id: this.idDelete
      })
      .then((response) => {
        this.$dialog.message.info('El grupo se ha sido eliminado con éxito')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.idDelete = ''
        this.dialogDelete = false
      })
    },
    goTo (d = {}) {
      this.$router.push({ name: 'SegmentsRetrieveUpdate', params: { id: d.id, ...this.$route.params} })
    },
    remove (item) {
      const index = this.editable.members.indexOf(item)
      if (index >= 0) this.editable.members.splice(index, 1)
    }
  },
  validations: {
    editable: {
      name: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(100) },
      visibility: { required },
      members: {
      required: requiredIf(function () {
        return ![1, 3].includes(this.editable.visibility)
      })
      }
    },
    newPos: {
      name: { required, maxLength: maxLength(100) },
      branch: { required, maxLength: maxLength(100) },
      store_path: { required },
      irs_store_path: { required },
      status_reason: { required }
    }
  }
}
</script>
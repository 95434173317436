export default [
    {
        id: "seg_qsgVnf8ixhJItmv3Z4",
        name: "Todos los proveedores",
        description: "Proveedores",
        visibility: 2,
        created: "2023-10-02T17:51:13.773994-03:00",
        updated: "2023-10-02T18:34:40.801629-03:00",
        pos: ['sup_dWOo4nvjwS4QIIDe9h', 'sup_dWOo4nvjwS4QIIDe93', 'sup_87CSPFwwSGU1oXRWh3'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc", "sup_hUdf2lcrnfQyUqaYsr", "sup_VqCvECpD7RFiJQEa7h", "sup_kliVwOvnyBW3uEgFzn", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD", "sup_FOS1YeYiTSfo1Ibm0S", "sup_bGAgOFq2sHLqUjxVC8"
        ]
    },
    {
        id: "seg_qsgVnf8ixhJItmv3Zw",
        name: "Zona Amazonas",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:51:13.773994-03:00",
        updated: "2023-10-02T18:34:40.801629-03:00",
        pos: ['sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Áncash",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Apurímaca",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_VqCvECpD7RFiJQEa7h", "sup_kliVwOvnyBW3uEgFzn", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Ayacucho",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Cajamarca",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Callao",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Cusco",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_VqCvECpD7RFiJQEa7h", "sup_kliVwOvnyBW3uEgFzn", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Huancavelica",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Huánuco",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_VqCvECpD7RFiJQEa7h", "sup_kliVwOvnyBW3uEgFzn", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Ica",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Junín",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona La Libertad",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Lambayeque",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_VqCvECpD7RFiJQEa7h", "sup_kliVwOvnyBW3uEgFzn", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Lima",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_VqCvECpD7RFiJQEa7h", "sup_kliVwOvnyBW3uEgFzn", "sup_U0RvgqpqGmKY6wUNzN"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Lima Provincia",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Loreto",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Madre de Dios",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Moquegua",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_87CSPFwwSGU1oXRWh3", "sup_U0RvgqpqGmKY6wUNzN", "sup_n1PO8UozhMWa1HBbDD"
        ]
    },
    {
        id: "seg_tqvZFBPwq86mbfIg2V",
        name: "Zona Pasco",
        description: "Proveedores",
        visibility: 3,
        created: "2023-10-02T17:25:50.742558-03:00",
        updated: "2023-10-02T17:58:46.269106-03:00",
        pos: ['sup_87CSPFwwSGU1oXRWh3', 'sup_dWOo4nvjwS4QIIDe9h'],
        members: [
            "sup_87CSPFwwSGU1oXRWh3", "sup_tzu0QEeW0eomVKqL1n", "sup_Fe0pr9XMm2SN96iKpG", "sup_nEuwGqWCbc0vHRS2wV", "sup_UrnDSQUK45hJiLn6qd", "sup_umPoOkXUq5wKToJem2", "sup_mjGIVrOHYeF2rb6pI8", "sup_iIYgSiLEQITPbujJAe", "sup_vBb7IBMxgsWEqc1g9P", "sup_933KpWzYBwclSl3HYK", "sup_c6bfm1ADJhoRkefDuc"
        ]
    }
]